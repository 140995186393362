import { createSlice, current } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import {
  getFilters,
  getFormColumns,
  getFormData,
  getFormStatus,
  getAnalysis,
  getAIBuilder,
  getQuotaData,
  getDownloadData,
  getIndustryList,
  getTaxonomyById,
  getTaxonomyList,
  getTranscriptStatus,
  getAllTranscription,
  getComparators,
  getThemeList,
  getVideoList,
  getOverallColumns,
  getOverallData,
  getOverallStatus,
  getSavedFilterList,
  getShowReelsList,
  getShowReelsUploadImages,
  getReelElements,
  getDownloadFilesList,
  getUploadList,
  getSingleExcel,
  getContentAnalysisColumn,
  getContentAnalysisData,
} from '../asyncActions/dashboardActions';
import { industryType, categoryType } from './Types';

type sortObjectType = {
  dataIndex: string;
  desc: boolean;
};

type transcriptionStatusObj = {
  approved?: number;
  notApproved?: number;
  notUpdated?: number;
  proofRead?: number;
  total?: number;
  updated?: number;
  videoTime?: number;
};

export type transcriptionDataObj = {
  isAnalysisApproved: boolean;
  isUpdate: boolean;
  transcriberName: string;
  transcript: string;
  url: string;
  userId: number;
  videoId: number;
  editable?: boolean;
  questionOrderId: number;
  userName?: string;
  userEmail?: string;
  answerType?: string;
};

type FilterObjectProp = {
  filterType?: string;
  filterId?: string;
  value?: string;
};

interface dashboardData {
  loading: boolean;
  formStatus?: object;
  overallStatus?: Array<object>;
  formColumns?: Array<object>;
  overallColumns?: Array<object>;
  formData: Array<object>;
  overallData: Array<object>;
  formDataTotalCount: number;
  overAllDataTotalCount: number;
  filterDataValue: string;
  filters?: object;
  selectedFilters?: object;
  selectedComparisonQns: Array<object>;
  formSortObject?: sortObjectType;
  overallDataSortObject?: sortObjectType;
  analysisData?: any;
  analysisDataExcelValue?: any;
  valueNps?: object;
  matrixData?: object;
  AIBuilderData?: object;
  quotaData?: object;
  downloadData?: object;
  newdownloadData?: object;
  selectedTaxonomyId?: number | null;
  selectedCategoryId?: number | null;
  selectedIndustryId?: number | null;
  industryList?: industryType[];
  categoryList?: categoryType[];
  taxonomyList: object;
  taxonomyDropdownList?: industryType[];
  transcriptionStatus?: transcriptionStatusObj;
  allTranscriptionData?: transcriptionDataObj[];
  allTranscriptionDataTotalCount: number;
  formDataLoading: boolean;
  overAllDataLoading: boolean;
  comparator: object;
  total: string;
  themeList: Array<object>;
  videoList: Array<object>;
  videoListCount: number;
  getAnalysisReqObj: object;
  isSaving: boolean | null;
  longFormatUploadingFiles: Array<object>;
  savedFilterList: Array<object>;
  selectedFilterId: number | null;
  showReelsList: Array<object>;
  showReelsUploadedImages: Array<object>;
  currentReelLayers: Array<object>;
  updateTimelineTextData: object;
  updateTimelineImageData: object;
  downloadFilesList: Array<object>;
  uploadList: Array<object>;
  excelData: any;
  contentAnalysisColumns: any;
  contentAnalysisData: any;
  quadrantChartData: any;
  dataTableCurrentlyUploadingFiles: Array<any>;
  longFormatUploadingFilesBatchId: number;
}

const initialState: dashboardData = {
  loading: false,
  filters: {},
  selectedFilters: {},
  selectedComparisonQns: [{}],
  formStatus: {},
  overallStatus: [],
  formColumns: [],
  overallColumns: [],
  formData: [],
  overallData: [],
  formDataTotalCount: 0,
  overAllDataTotalCount: 0,
  filterDataValue: '',
  formSortObject: { dataIndex: 'id', desc: true },
  overallDataSortObject: { dataIndex: 'id', desc: true },
  analysisData: [],
  analysisDataExcelValue: [],
  matrixData: {},
  AIBuilderData: {},
  quotaData: {},
  downloadData: {},
  newdownloadData: {},
  industryList: [],
  categoryList: [],
  taxonomyList: {},
  taxonomyDropdownList: [],
  transcriptionStatus: {},
  allTranscriptionData: [],
  allTranscriptionDataTotalCount: 0,
  valueNps: {},
  formDataLoading: true,
  overAllDataLoading: false,
  comparator: {},
  total: '',
  themeList: [],
  videoList: [],
  videoListCount: 0,
  getAnalysisReqObj: {},
  isSaving: null,
  longFormatUploadingFiles: [],
  savedFilterList: [],
  selectedFilterId: null,
  showReelsList: [],
  showReelsUploadedImages: [],
  currentReelLayers: [],
  updateTimelineTextData: {},
  updateTimelineImageData: {},
  downloadFilesList: [],
  uploadList: [],
  excelData: [],
  contentAnalysisColumns: [],
  contentAnalysisData: [],
  quadrantChartData: null,
  dataTableCurrentlyUploadingFiles: [],
  longFormatUploadingFilesBatchId: -1,
};

export const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    emptyFormData: (state) => {
      state.formColumns = initialState.formColumns;
      state.formData = initialState.formData;
      state.formDataTotalCount = initialState.formDataTotalCount;
      state.formSortObject = initialState.formSortObject;
      state.overallDataSortObject = initialState.overallDataSortObject;
      state.formDataLoading = true;
    },
    setDataTableCurrentlyUploadingFiles: (state, action) => {
      state.dataTableCurrentlyUploadingFiles = action.payload;
    },
    setLongFormatUploadingFilesBatchId: (state, action) => {
      state.longFormatUploadingFilesBatchId = action.payload;
    },
    setLongFormatFiles: (state, action) => {
      state.longFormatUploadingFiles = action.payload;
    },
    setIsSaving: (state, action) => {
      state.isSaving = action.payload;
    },
    setFormSortObject: (state, action) => {
      state.formSortObject = action.payload;
    },
    setOverallDataSortObject: (state, action) => {
      state.overallDataSortObject = action.payload;
    },
    setDemoGraphyFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setComparisonQns: (state, action) => {
      state.selectedComparisonQns = action.payload;
    },
    setSelectedFilterId: (state, action) => {
      state.selectedFilterId = action.payload;
    },
    setTaxonomyDropDown: (state, action) => {
      const [selectedIndustryId, selectedCategoryId] = action.payload;
      state.selectedIndustryId = selectedIndustryId;
      state.selectedCategoryId = selectedCategoryId;
    },
    deleteFilter: (state, action) => {
      const { key, value } = action.payload;
      let currentFilters = state.selectedFilters;
      let index = currentFilters[key].indexOf(value);
      if (index !== -1) {
        currentFilters[key].splice(index, 1);
      }
      state.selectedFilters = currentFilters;
    },
    deleteCompareQuestion: (state, action) => {
      const { value, id, header } = action.payload;

      let currentSelectedQuestions = current(state).selectedComparisonQns;
      let questionIndex = currentSelectedQuestions.findIndex(
        (question: any) => question.questionId === id,
      );
      let currentQuestion = currentSelectedQuestions[questionIndex];
      let answerType = currentQuestion.answerType;
      let answer = cloneDeep(currentQuestion.answer);
      switch (answerType) {
        case 'matrix':
        case 'video':
        case 'AVT':
        case 'AVT-Text':
        case 'imageCapture':
        case 'audio': {
          answer[header] = answer[header].filter((item: any) => item !== value);
          let newQuestionObject = {
            questionId: id,
            answerType: answerType,
            answer: answer,
          };
          currentSelectedQuestions = currentSelectedQuestions.slice();
          currentSelectedQuestions[questionIndex] = newQuestionObject;
          break;
        }
        default: {
          answer = answer.filter((item: any) => item !== value);
          let newQuestionObject = {
            questionId: id,
            answerType: answerType,
            answer: answer,
          };
          currentSelectedQuestions = currentSelectedQuestions.slice();
          currentSelectedQuestions[questionIndex] = newQuestionObject;
          break;
        }
      }
      state.selectedComparisonQns = currentSelectedQuestions;
    },
    clearFIlters: (state) => {
      state.selectedFilters = initialState.selectedFilters;
      state.selectedComparisonQns = initialState.selectedComparisonQns;
    },
    setAnalysisReqObjVideo: (state, action) => {
      state.getAnalysisReqObj = action.payload;
    },
    setUpdateTimlineTextData: (state, action) => {
      state.updateTimelineTextData = action.payload;
    },
    setUpdateTimlineImageData: (state, action) => {
      state.updateTimelineImageData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getContentAnalysisData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getContentAnalysisData.fulfilled, (state, action) => {
        state.contentAnalysisData = action.payload.data.value;
        state.loading = false;
      })
      .addCase(getContentAnalysisData.rejected, (state, payload) => {
        state.loading = false;
      })
      .addCase(getContentAnalysisColumn.pending, (state) => {
        state.loading = true;
      })
      .addCase(getContentAnalysisColumn.fulfilled, (state, action) => {
        state.contentAnalysisColumns = action.payload.data.value;
        state.loading = false;
      })
      .addCase(getContentAnalysisColumn.rejected, (state, payload) => {
        state.loading = false;
      })
      .addCase(getSingleExcel.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleExcel.fulfilled, (state, action) => {
        state.excelData = action.payload.data.value;
        state.loading = false;
      })
      .addCase(getSingleExcel.rejected, (state, payload) => {
        state.loading = false;
      })
      .addCase(getUploadList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUploadList.fulfilled, (state, action) => {
        state.uploadList = action.payload.data.value;
        state.loading = false;
      })
      .addCase(getUploadList.rejected, (state, payload) => {
        state.loading = false;
      })
      .addCase(getFilters.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFilters.fulfilled, (state, action) => {
        state.filters = action.payload.data.value;
        state.loading = false;
      })
      .addCase(getFilters.rejected, (state, payload) => {
        state.loading = false;
      })
      .addCase(getComparators.pending, (state) => {
        state.loading = true;
      })
      .addCase(getComparators.fulfilled, (state, action) => {
        state.comparator = action.payload.data.value;
        state.loading = false;
      })
      .addCase(getComparators.rejected, (state, payload) => {
        state.loading = false;
      })

      .addCase(getAnalysis.pending, (state) => {
        state.analysisData = [];
        state.analysisDataExcelValue = [];
        state.matrixData = {};
        state.valueNps = {};
        state.loading = true;
        state.total = '';
      })
      .addCase(getAnalysis.fulfilled, (state, action) => {
        if (action.payload.data.answerType === 'matrix') {
          state.matrixData = action.payload.data.value;
          state.analysisDataExcelValue = action.payload.data.excelValue || [];
          state.total = action.payload.data.total || '';
        } else if (action.payload.data.answerType === 'quadrantChart') {
          state.quadrantChartData = {
            excelValue: action.payload.data.excelValue ?? [],
            questionObject: action.payload.data.questionObject ?? {},
          };
          state.total = action.payload.data.total || '';
          state.analysisDataExcelValue = action.payload.data.excelValue || [];
        } else {
          state.analysisData = action.payload.data.value;
          state.analysisDataExcelValue = action.payload.data.value || [];
          state.valueNps = action.payload.data.valueNps || {};
          state.total = action.payload.data.total || '';
        }
        state.loading = false;
      })
      .addCase(getAnalysis.rejected, (state, payload) => {
        state.loading = false;
      })
      .addCase(getFormStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFormStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.formStatus = action.payload.data.value;
      })
      .addCase(getFormStatus.rejected, (state, payload) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getOverallStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOverallStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.overallStatus = action?.payload?.data?.value ?? [];
      })
      .addCase(getOverallStatus.rejected, (state, payload) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getFormColumns.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFormColumns.fulfilled, (state, action) => {
        state.loading = false;
        state.formColumns = action.payload.data.value;
      })
      .addCase(getFormColumns.rejected, (state, payload) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getOverallColumns.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOverallColumns.fulfilled, (state, action) => {
        state.loading = false;
        state.overallColumns = action?.payload?.data?.value ?? [];
      })
      .addCase(getOverallColumns.rejected, (state, payload) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getOverallData.pending, (state) => {
        state.overAllDataLoading = true;
      })
      .addCase(getOverallData.fulfilled, (state, action) => {
        state.overallData = action?.payload?.data?.value ?? [];
        state.overAllDataTotalCount = action?.payload?.data?.totalCount ?? 0;
        state.overAllDataLoading = false;
      })
      .addCase(getOverallData.rejected, (state, payload) => {
        state.overAllDataLoading = false;
        state = initialState;
      })
      .addCase(getFormData.pending, (state) => {
        state.formDataLoading = true;
      })
      .addCase(getFormData.fulfilled, (state, action) => {
        state.formData = action.payload.data.data;
        state.formDataTotalCount = action.payload.data.totalCount;
        state.formDataLoading = false;
      })
      .addCase(getFormData.rejected, (state, payload) => {
        state.formDataLoading = false;
        state = initialState;
      })
      .addCase(getAIBuilder.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAIBuilder.fulfilled, (state, action) => {
        state.loading = false;
        state.AIBuilderData = action.payload.data.value;
      })
      .addCase(getAIBuilder.rejected, (state, payload) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getQuotaData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuotaData.fulfilled, (state, action) => {
        state.loading = false;
        state.quotaData = action.payload.data;
      })
      .addCase(getQuotaData.rejected, (state, payload) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getDownloadData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDownloadData.fulfilled, (state, action) => {
        state.loading = false;
        state.downloadData = action.payload.data;
      })
      .addCase(getDownloadData.rejected, (state, payload) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getIndustryList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getIndustryList.fulfilled, (state, action) => {
        state.loading = false;
        state.industryList = action.payload.data.industry;
        state.categoryList = action.payload.data.category;
      })
      .addCase(getIndustryList.rejected, (state, payload) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getTaxonomyById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTaxonomyById.fulfilled, (state, action) => {
        state.loading = false;
        state.taxonomyList = action.payload.data.value;
      })
      .addCase(getTaxonomyById.rejected, (state, payload) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getTaxonomyList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTaxonomyList.fulfilled, (state, action) => {
        state.loading = false;
        state.taxonomyDropdownList = action.payload.data.value;
      })
      .addCase(getTaxonomyList.rejected, (state, payload) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getTranscriptStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTranscriptStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.transcriptionStatus = action.payload.data.transcriptStatus;
      })
      .addCase(getTranscriptStatus.rejected, (state) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getAllTranscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTranscription.fulfilled, (state, action) => {
        state.loading = false;
        state.allTranscriptionData = action.payload.data.value;
        state.allTranscriptionDataTotalCount = action.payload.data.count;
      })
      .addCase(getAllTranscription.rejected, (state) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getThemeList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getThemeList.fulfilled, (state, action) => {
        state.loading = false;
        state.themeList = action.payload.data.value;
      })
      .addCase(getThemeList.rejected, (state) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getVideoList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVideoList.fulfilled, (state, action) => {
        state.loading = false;
        state.videoListCount = action.payload.data.count;
        state.videoList = action.payload.data.value;
      })
      .addCase(getVideoList.rejected, (state) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getSavedFilterList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSavedFilterList.fulfilled, (state, action) => {
        state.loading = false;
        state.savedFilterList = action.payload.data.value;
      })
      .addCase(getSavedFilterList.rejected, (state) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getShowReelsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getShowReelsList.fulfilled, (state, action) => {
        state.loading = false;
        state.showReelsList = action.payload.data.value;
      })
      .addCase(getShowReelsList.rejected, (state) => {
        state.loading = false;
        state.showReelsList = [];
      })
      .addCase(getShowReelsUploadImages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getShowReelsUploadImages.fulfilled, (state, action) => {
        state.loading = false;
        state.showReelsUploadedImages = action.payload.data.value;
      })
      .addCase(getShowReelsUploadImages.rejected, (state) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getReelElements.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReelElements.fulfilled, (state, action) => {
        state.loading = false;
        state.currentReelLayers = [
          ...action.payload.data.value.mainTimeLine,
          ...action.payload.data.value.textTimeLine,
        ];
      })
      .addCase(getReelElements.rejected, (state) => {
        state.loading = false;
        state.currentReelLayers = [];
      })
      .addCase(getDownloadFilesList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDownloadFilesList.fulfilled, (state, action) => {
        state.loading = false;
        state.downloadFilesList = action.payload.data.value;
      })
      .addCase(getDownloadFilesList.rejected, (state) => {
        state.loading = false;
        state = initialState;
      });
  },
});

export const { actions: dashboardActions } = DashboardSlice;
export default DashboardSlice.reducer;
